










































import {Component, Vue} from "vue-property-decorator";



@Component
export default class CenterView extends Vue {
    loading: boolean = false
    page: number = 1
    pageCount: number = 0
    itemsPerPage: number = 8
    totalItems: number = 0
}
